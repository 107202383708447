import React from "react";
import Container from "../components/Container/Container";
import Seo from "../components/Seo/Seo";
import G from "../images/groenhout_g.png"
import Image from "../images/groenhout-assen-1.jpg"
import SmallHeroNew from "../components/SmallHeroNew/SmallHeroNew";
import GravityForm from "../components/GravityForm";
import { graphql, useStaticQuery } from "gatsby";
import Testimonial from "../components/Testimonials/Testimonials";

export default function GratisWaardebepaling() {
  const seo = useStaticQuery(
    graphql`
      query WaardebepalingPage {
        wpPage(slug: { eq: "gratis-waardebepaling" }) {
          seo {
            metaDesc
            title
          }
          content
          featuredImage {
            node {
              mediaItemUrl
            }
          }
        }
      }
    `
  )

  const image = seo.wpPage.featuredImage?.node.mediaItemUrl

  return (
    <>
      <Seo title={seo.wpPage.seo.title} description={seo.wpPage.seo.metaDesc} />
      <SmallHeroNew
        title="Gratis waardebepaling"
        desc="Benieuwd naar de waarde van uw woning? Nodig ons uit voor een gratis en geheel vrijblijvende waardebepaling!"
        overlay={true}
        overlayImage={G}
        image={image !== null ? image : Image}
      ></SmallHeroNew>
      <div className="text-content">
        <Container>
          <div dangerouslySetInnerHTML={{ __html: seo.wpPage.content }} className="text-content-small"></div>
        </Container>
      </div>
      <div className="bg-light py-8 lg:py-16 px-8 lg:px-0">
        <Container>
          <div className="p-8 bg-white w-full lg:w-2/5 mx-auto">
            <h2 className="text-blue text-3xl">Neem de volgende stap!</h2>
            <GravityForm id={"7"} colorClass={`bg-light`}></GravityForm>
          </div>
        </Container>
        <Testimonial></Testimonial>
      </div>
    </>
  )
}